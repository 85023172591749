import { useState } from "react"
import { AiOutlineDelete } from "react-icons/ai"
import { BiEditAlt } from "react-icons/bi"
import { Link } from "react-router-dom"
import deleteContact from "../../features/contact/deleteContact"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import loading from '../../assests/loading.gif'
export default function  PreOrderCard({order}) {
    
    const [openModal, setOpenModal] = useState(false)
    console.log(order)


    
    return (
        <>
       
            <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{order.name}</h5>
                </a>
                <ul class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    <li>Frame Name : {order.frameName}</li>
                    <li className="flex items-center">Frame Color : <span className={`w-[20px] h-[20px] m-2 rounded-full  inline-block`} style={{ background: order.frameColor }}></span></li>
                    <li>loupe Name :  {order.loopName}</li>
                    <li className="flex items-center">loupe Color : <span className={`w-[20px] h-[20px] m-2 rounded-full  inline-block`} style={{ background: order.loopColor }}></span></li>
                    <li>email : {order.email}</li>
                    <li>country : {order.country}</li>
                    <li>city : {order.city}</li>
                    <li>address : {order.address}</li>
                    <li>zip_code : {order.zip_code}</li>
                </ul>
            
            </div>
        </>
    )
}