import { use } from "react"
import DashboardLayout from "../../layout/dashboardLayout"
import { useQuery } from "@tanstack/react-query"
import getPreOrders from "../../features/preOrders/getPreOrders"
import PreOrderCard from "../../components/preOrderCard/preOrderCard"


export default function PreOrder() {

    
    const { data, isLoading } = useQuery({ queryKey: ['preOrders'], queryFn: getPreOrders })

    return (

        <DashboardLayout>
                  <div className="flex justify-between items-center">
                  <h1 className="text-3xl mb-3 font-bold">Pre Orders</h1>
                </div>
                 <div className="grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-3 ">
                    {
                    data?.map((order)=>{
                            return(
                                <PreOrderCard order={order}/>
                            )
                        })
                    }
                </div>
            </DashboardLayout>
)
}