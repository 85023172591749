import { MdOutlineRateReview } from "react-icons/md"; 
import * as React from 'react';
import { Link } from "react-router-dom";

function ResponsiveDrawer(props) {


  return (
   <>
   


  

      <aside id="logo-sidebar" class={props.openSidebar ? "fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700 transform-none" : `
        
        fixed top-0 left-0 z-40 w-64  h-screen pt-20 transition-transform -translate-x-full border-r  sm:translate-x-0 bg-gray-800 border-gray-700`} aria-label="Sidebar">
        <div class="h-full px-3 pb-4 overflow-y-auto  bg-gray-800">
            <ul class="space-y-2 font-medium">
             
              <li>
                  <Link to="/Testimonials" class="flex items-center p-2  rounded-lg text-white  hover:bg-gray-700 group">
                   <MdOutlineRateReview />
                    <span class="ms-3">Testimonials</span>
                  </Link>
              </li>
              <li>
                  <Link to="/Profession" class="flex items-center p-2  rounded-lg text-white  hover:bg-gray-700 group">
                   <MdOutlineRateReview />
                    <span class="ms-3">Profession</span>
                  </Link>
              </li>
              <li>
                  <Link to="/ergonomics" class="flex items-center p-2  rounded-lg text-white  hover:bg-gray-700 group">
                   <MdOutlineRateReview />
                    <span class="ms-3">ergonomics</span>
                  </Link>
              </li>
              <li>
                  <Link to="/Lights" class="flex items-center p-2  rounded-lg text-white  hover:bg-gray-700 group">
                   <MdOutlineRateReview />
                    <span class="ms-3">Lights & Loups</span>
                  </Link>
              </li>
              <li>
                  <Link to="/contact" class="flex items-center p-2  rounded-lg text-white  hover:bg-gray-700 group">
                   <MdOutlineRateReview />
                    <span class="ms-3">contacts</span>
                  </Link>
              </li>
              {/* <li>
                  <Link to="/price" class="flex items-center p-2  rounded-lg text-white  hover:bg-gray-700 group">
                   <MdOutlineRateReview />
                    <span class="ms-3">Price</span>
                  </Link>
              </li> */}
              <li>
              <Link to="/preOrders" class="flex items-center p-2  rounded-lg text-white  hover:bg-gray-700 group">
                   <MdOutlineRateReview />
                    <span class="ms-3">Pre orders</span>
                  </Link>
              </li>
              <li>
              <Link to="/orders" class="flex items-center p-2  rounded-lg text-white  hover:bg-gray-700 group">
                   <MdOutlineRateReview />
                    <span class="ms-3">carbon X orders</span>
                  </Link>
              </li>
             
         
            </ul>
        </div>
      </aside>

    


   </>
  );
}



export default ResponsiveDrawer;
