import { use } from "react"
import DashboardLayout from "../../layout/dashboardLayout"
import { useQuery } from "@tanstack/react-query"
import PreOrderCard from "../../components/preOrderCard/preOrderCard"
import getOrders from "../../features/orders/getOrders"
import OrderCard from "../../components/orderCard/orderCard"


export default function Orders() {

    
    const { data, isLoading } = useQuery({ queryKey: ['OrdersCarbon'], queryFn: getOrders })

    return (

        <DashboardLayout>
                  <div className="flex justify-between items-center">
                  <h1 className="text-3xl mb-3 font-bold">Carbon X Orders</h1>
                </div>
                 <div className="grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-3 ">
                    {
                    data?.map((order)=>{
                            return(
                                <OrderCard order={order}/>
                            )
                        })
                    }
                </div>
            </DashboardLayout>
)
}