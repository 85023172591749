import { useState } from "react";
import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/Sidebar";
import { Container } from "@mui/material";
export default function DashboardLayout({ children }) {
    
    const [openSidebar , setOpenSidebar] = useState(false)
    return (

        <>
            <Navbar setOpenSidebar={setOpenSidebar} openSidebar={openSidebar} />
            <Sidebar openSidebar={openSidebar} />
            <div class="p-4 sm:ml-64 mt-16 ">
      
          {children}
         </div>
             
            
          </>
        
    )
}