// src/components/Navbar.js
import React, { useState } from "react";
import logoImg from '../assets/img/logo.png'
import loginAtom from "../../atoms /loginAtom";
import tokenAtom from "../../atoms /tokenAtom";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
const Navbar = ({ openSidebar, setOpenSidebar }) => {
  
  const [active , setActive] = useState(false)
  const [isLoggin , setIsloggin] = useRecoilState(loginAtom)
    const [token , setToken] = useRecoilState(tokenAtom)

  return (
    <>
        <nav class="fixed  top-0 z-50 w-full  bg-gray-800 border-gray-700 border-gray-700 border-b">
        <div class="px-3 py-3 lg:px-5 lg:pl-3">
          <div class="flex items-center justify-between">
            <div class="flex items-center justify-start rtl:justify-end">
              <button onClick={() => {
                setOpenSidebar(!openSidebar)
              }} data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" class="inline-flex relative z-50 items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                  <span class="sr-only">Open sidebar</span>
                  <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                  </svg>
              </button>
              <Link to="/" class="flex ms-2 md:me-24">
                <img src={logoImg} class="h-5 me-3" alt="FlowBite Logo" />
              </Link>
            </div>
            <div class="flex items-center">
                <div class="flex items-center ms-3">
                  <div className="relative">
                    <button onClick={()=>{
                      setActive(!active)
                    }} type="button" class="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" aria-expanded="false" data-dropdown-toggle="dropdown-user">
                      <span class="sr-only">Open user menu</span>
                      <img class="w-8 h-8 rounded-full" src="https://flowbite.com/docs/images/people/profile-picture-5.jpg" alt="user photo"/>
                    </button>
                  </div>
                  <div class={`z-50 ${active? "block" : "hidden"} my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600 absolute top-10 right-0`} id="dropdown-user">
                    <div class="px-4 py-3" role="none">
                      <p class="text-sm text-gray-900 dark:text-white" role="none">
                        Neil Sims
                      </p>
                      <p class="text-sm font-medium text-gray-900 truncate dark:text-gray-300" role="none">
                        neil.sims@flowbite.com
                      </p>
                    </div>
                    <ul class="py-1" role="none">
                   
                      <li>
                        <button onClick={()=>{
                           setIsloggin(false)
                            setToken("")
                        }} class="block px-4 w-full py-2 text-sm text-gray-300 hover:bg-gray-600 hover:text-white" role="menuitem">Sign out</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </nav>
    </>

  );
};

export default Navbar;
